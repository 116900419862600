import React from "react";
import "./card-mockup.scss";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Button } from "../Button/Button";

export const CardMockup = ({ title, description, image, link, linkText }) => {
  const parsedImage = getImage(image);
  return (
    <div className="card-mockup">
      <div className="card-mockup__image">
        <GatsbyImage image={parsedImage} alt={description} className="image" />
      </div>

      <div className="card-mockup__data">
        <h4 className="heading">{title}</h4>
        <p className="description">{description}</p>
        <Button link={link} primary>
          {linkText}
        </Button>
      </div>
    </div>
  );
};
