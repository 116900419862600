import React from "react";
import "./card-quote.scss";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

export const CardQuote = ({ fullName, citation, position, image }) => {
  const parsedImage = getImage(image);
  return (
    <div className="card-quote">
      <div className="card-quote__content">
        <div className="citation-holder">
          <p className="citation">{citation}</p>
        </div>
        <div className="details">
          <div className="author">
            <GatsbyImage image={parsedImage} alt={fullName} className="image" />
            <div className="info">
              <p className="name">{fullName}</p>
              <p className="position">{position}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
