import React, { Fragment } from 'react';
import './items.scss';
import { IntroHolder } from '../IntroHolder/IntroHolder';


export const ItemsHolder = ({ title, children }) => {
  return (
    <>
      <section className="items">
        <div className="container">
          <div className="gr-12">
            <div className="items__title-holder">
              <IntroHolder
                title={title}
                level="h3"
                centered
                small
              />
            </div>
            <div className="items__wrap">
                {children}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};